export const navBarConent = {
  es: {
    home: 'home',
    about: 'about',
    articles: 'artículos',
    projects: 'proyectos',
    language: 'ES'
  },
  en: {
    home: 'home',
    about: 'about',
    articles: 'articles',
    projects: 'projects',
    language: 'EN'
  },
}
